<template>
    <div>
        <div class="bg-white text-purple rounded">
            <div class="hstack justify-content-between fs-5 fw-semibold p-3 border-bottom">
                <div>
                    {{ edit ? "Editar" : "Criar" }} Template HSM
                </div>
                <b-button variant="success" class="text-white px-4" @click="$router.replace('/manageHSMModels')">Voltar</b-button>
            </div>
            <div class="bg-white rounded-bottom p-3 text-center" v-if="loading">
                <b-spinner />
            </div>
            <b-row class="m-0" v-else>
                <b-col class="p-0" cols="7">
                    <b-form class="vstack gap-3 p-3" @submit.prevent="saveTemplate()">
                        <b-form-group label="Canal:" label-class="fw-semibold" v-if="!edit && channels.length > 1">
                            <v-select class="customSelect" label="channelNumber" :reduce="channel => channel._id" :options="channels" :clearable="false" v-model="template.channelId" required @input="onChannelChange()" />
                        </b-form-group>
                        <b-form-group label="Nome:" label-class="fw-semibold">
                            <b-form-input class="border-grey" placeholder="Nome"
                                :state="isValidName"
                                aria-describedby="name-live-help name-live-feedback"
                                v-model="template.elementName"
                                required
                                @input="onInputElementName"
                                :disabled="edit && templateChannel?.apiType === 'cloud'"
                            />
                            <b-form-invalid-feedback id="name-live-feedback">
                                {{ invalidNameMessage }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group label="Função:" label-class="fw-semibold">
                            <b-form-radio-group class="hstack gap-3" v-model="template.type" required>
                                <b-form-radio class="hstack gap-1" value="campaign">
                                    Campanha
                                </b-form-radio>
                                <b-form-radio class="hstack gap-1" value="active_communication">
                                    Atendimento
                                </b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label="Categoria:" label-class="fw-semibold">
                            <b-form-radio-group class="hstack gap-3" v-model="template.category" required :disabled="edit && templateChannel?.apiType === 'cloud' && template.status === 'APPROVED'">
                                <b-form-radio class="hstack gap-1" value="MARKETING">
                                    Marketing
                                </b-form-radio>
                                <b-form-radio class="hstack gap-1" value="UTILITY">
                                    Utilidade
                                </b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label="Tipo:" label-class="fw-semibold">
                            <b-form-radio-group class="hstack gap-3" required>
                                <b-form-radio class="hstack gap-1" checked>
                                    Mensagem Personalizada
                                </b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <b-form-group label="Conteúdo:" label-class="fw-semibold">
                            <b-form-radio-group class="hstack gap-3" v-model="template.templateType" @change="changedCategory" required>
                                <b-form-radio class="hstack gap-1" value="TEXT">
                                    Texto
                                </b-form-radio>
                                <b-form-radio class="hstack gap-1" value="IMAGE">
                                    Imagem
                                </b-form-radio>
                                <b-form-radio class="hstack gap-1" value="VIDEO">
                                    Vídeo
                                </b-form-radio>
                                <b-form-radio class="hstack gap-1" value="DOCUMENT">
                                    Documento
                                </b-form-radio>
                                <b-form-radio class="hstack gap-1" value="PRODUCT" disabled>
                                    Catálogo
                                </b-form-radio>
                            </b-form-radio-group>
                            <div class="mt-2" v-if="template.templateType">
                                <b-form-textarea ref="textAreaText" class="border-grey" maxlength="1028" rows="5" no-resize v-model="template.content" placeholder="Conteúdo" required v-if="template.templateType === 'TEXT'" @input="onContentInput" />
                                <div class="addMedia" v-if="template.templateType === 'IMAGE'">
                                    <b-form-file id="menuImgUpload" style="display:none" accept="image/*" @input="selectMenuMedia"></b-form-file>
                                    <label for='menuImgUpload' class="w-100">
                                        <div class="vstack align-items-center justify-content-center border-grey2 rounded" style="height: 25em;" role="button" v-if="!template.url">
                                            <b-icon font-scale="2" icon="image"></b-icon>
                                            <div class="fs-5">Adicionar Imagem</div>
                                        </div>
                                        <div style="max-height:25em;" role="button" v-else>
                                            <b-img class="object-fit-contain" style="max-height: 25em; width: 100%" :src="template.url" />
                                        </div>
                                    </label>
                                </div>
                                <div class="addMedia" v-if="template.templateType === 'VIDEO'">
                                    <b-form-file id="menuImgUpload" style="display:none" accept="video/*" @input="selectMenuMedia"></b-form-file>
                                    <label for='menuImgUpload' class="w-100">
                                        <div class="vstack align-items-center justify-content-center border-grey2 rounded" style="height: 25em;" role="button" v-if="!template.url">
                                            <b-icon font-scale="3" icon="play-fill"></b-icon>
                                            <div class="fs-5">Adicionar Vídeo</div>
                                        </div>
                                        <div v-else>
                                            <div style="max-height:25em;">
                                                <video controls style="max-height: 25em; width: 100%" :src="template.url"></video>
                                            </div>
                                            <div class="small-text text-center" role="button">
                                                Alterar Vídeo
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div class="addMedia" v-if="template.templateType === 'DOCUMENT'">
                                    <b-form-file id="menuImgUpload" style="display:none" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow" @input="selectMenuMedia"></b-form-file>
                                    <label for='menuImgUpload' class="w-100">
                                        <div class="vstack align-items-center justify-content-center border-grey2 rounded" style="height: 25em;" role="button" v-if="!template.url">
                                            <b-icon font-scale="3" icon="file-earmark-spreadsheet-fill"></b-icon>
                                            <div class="fs-5">Adicionar Documento</div>
                                        </div>
                                        <div class="vstack align-items-center justify-content-center border-grey2 rounded" style="height: 25em;" role="button" v-else>
                                            <b-icon font-scale="3" icon="file-earmark-spreadsheet-fill"></b-icon>
                                            <div class="fs-5">{{ template.url.split('').reverse().join('').split('/')[0].split('').reverse().join('') }}</div>
                                        </div>
                                    </label>
                                </div>
                                <b-form-textarea ref="textAreaMedia" class="border-grey mt-3" maxlength="1028" rows="3" no-resize v-model="template.content" placeholder="Legenda" v-if="['IMAGE', 'VIDEO', 'DOCUMENT'].includes(template.templateType)"  @input="onContentInput" />
                                <div class="text-danger small-text mt-1" v-if="template.content?.length >= 1028">
                                    O limite de caracteres (1028) foi atingido!
                                </div>
                                <b-button class="mt-3 text-white" variant="success" v-b-modal.tags v-if="['TEXT', 'IMAGE', 'VIDEO', 'DOCUMENT'].includes(template.templateType)">Adicionar Variável</b-button>
                            </div>
                        </b-form-group>
                        <div class="vstack gap-3" v-if="template.templateType">
                            <b-form-group label="Exemplo:" label-class="fw-semibold" v-if="template.content && pickedTags.length">
                                <b-form-textarea class="border-grey" maxlength="1028" rows="5" no-resize v-model="template.example" placeholder="Exemplo" disabled />
                            </b-form-group>
                            <b-form-group label="Cabeçalho:" label-class="fw-semibold" v-if="template.category !== 'AUTHENTICATION' && (template.templateType === 'TEXT' || template.templateType === 'PRODUCT')">
                                <b-form-input class="border-grey" maxlength="60" v-model="template.header" placeholder="Cabeçalho" />
                                <div class="text-danger small-text mt-1" v-if="template.header?.length >= 60">
                                    O limite de caracteres (60) foi atingido!
                                </div>
                            </b-form-group>
                            <b-form-group label="Rodapé:" label-class="fw-semibold" v-if="template.category !== 'AUTHENTICATION'">
                                <b-form-input class="border-grey" maxlength="60" v-model="template.footer" placeholder="Rodapé" />
                                <div class="text-danger small-text mt-1" v-if="template.footer?.length >= 60">
                                    O limite de caracteres (60) foi atingido!
                                </div>
                            </b-form-group>
                        </div>
                        <b-form-group label="Idioma:" label-class="fw-semibold">
                            <v-select class="customSelect" :reduce="country => country.value" :options="countriesLanguages" v-model="template.languageCode" required />
                        </b-form-group>
                        <b-form-group label="Rótulo:" label-class="fw-semibold" v-if="templateChannel?.apiType !== 'cloud'">
                            <b-form-input class="border-grey" maxlength="60" v-model="template.vertical" placeholder="Descrição do Template" required />
                            <div class="text-danger small-text mt-1" v-if="template.footer?.length >= 60">
                                O limite de caracteres (60) foi atingido!
                            </div>
                        </b-form-group>
                        <div class="vstack gap-1">
                            <div class="hstack justify-content-between">
                                <div class="fw-semibold">Botões:</div>
                                <div>
                                    <b-iconstack class="darkenTextOnHover" font-scale="1.5" role="button" @click="addButton">
                                        <b-icon class="text-purple" stacked icon="circle-fill"></b-icon>
                                        <b-icon stacked icon="plus" variant="light"></b-icon>
                                    </b-iconstack>
                                </div>
                            </div>
                            <div class="vstack gap-2">
                                <b-row class="m-0 gap-2 align-items-end" v-for="(button, index) in template.buttons" :key="index">
                                    <b-col class="p-0" cols="4">
                                        <b-form-group label="Tipo:">
                                            <b-form-select class="w-100 border-grey rounded py-2" v-model="button.type" @change="buttonTypeChanged(button)">
                                                <b-form-select-option value="QUICK_REPLY">Resposta Rápida</b-form-select-option>
                                                <b-form-select-option value="URL">URL</b-form-select-option>
                                                <b-form-select-option value="PHONE_NUMBER">Telefone</b-form-select-option>
                                                <b-form-select-option value="MPM" v-if="template.templateType === 'PRODUCT'">MPM</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col class="p-0 position-relative" v-if="button.type">
                                        <b-form-group label="Texto:">
                                            <b-form-input type="text" class="border-grey" maxlength="25" placeholder="Texto" v-model="button.text" required />
                                        </b-form-group>
                                        <div class="text-danger small-text mt-1 position-absolute bottom-1" v-if="button.text?.length >= 25">
                                            O limite de caracteres (25) foi atingido!
                                        </div>
                                    </b-col>
                                    <b-col class="p-0" v-if="button.type !== 'QUICK_REPLY' && button.type !== 'MPM' && template.templateType !== 'PRODUCT'">
                                        <b-form-group label="URL:" v-if="button.type === 'URL'">
                                            <b-form-input type="text" class="border-grey" placeholder="URL" v-model="button.url" required />
                                        </b-form-group>
                                        <b-form-group label="Telefone:" v-else-if="button.type === 'PHONE_NUMBER'">
                                            <b-form-input type="tel" class="border-grey" placeholder="Telefone" v-model="button.phone_number" required />
                                        </b-form-group>
                                        <div v-else>
                                            <b-form-input class="border-grey" disabled />
                                        </div>
                                    </b-col>
                                    <b-col class="p-0 d-grid w-min" cols="1">
                                        <b-button variant="red" class="text-white manage-btn rounded mx-0 justify-self-end" style="height: 38px;" v-b-tooltip.hover.bottom="{ variant: 'red', customClass: 'top-0', boundary: 'document' }" title="Remover" @click.prevent="removeButton(button)">
                                            <b-icon icon="trash"></b-icon>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                            <!-- <div class="small-text text-secondary" v-if="!template.buttons?.length">
                                Nenhum botão!
                            </div> -->
                        </div>
                        <b-form-checkbox class="hstack gap-1" v-model="template.allowTemplateCategoryChange" :disabled="edit && templateChannel?.apiType === 'cloud'">
                            Permitir que a Meta altere a categoria do Template
                        </b-form-checkbox>
                        <div class="hstack gap-2">
                            <b-button type="submit" class="text-white px-4" variant="green" :disabled="saving">
                                <b-spinner small v-if="saving"/>
                                Salvar
                            </b-button>
                            <b-button class="border-grey px-3" variant="outline-secondary" @click="$router.replace('/manageHSMModels')">
                                Cancelar
                            </b-button>
                        </div>
                    </b-form>
                </b-col>
                <b-col class="d-flex flex-column p-3 bg-grey">
                    <!-- <div class="fw-semibold text-center">Preview</div> -->
                    <div class="d-flex flex-column border border-3 border-white rounded h-100 shadow-sm">
                        <div class="bg-green2 rounded-top" style="height: 3em;"></div>
                        <Whatsapp id="preview" class="h-100" height="100%" :mediaURL="mediaURL" 
                            :messages="messages"
                        />
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-modal id="tags" ref="tags" title="Selecione a Variável" centered hide-footer body-class="p-0" header-class="py-0">
            <div class="p-3 text-purple" v-if="loadingTags">
                <b-spinner />
            </div>
            <div v-else>
                <div class="border-bottom py-2 px-3 hstack justify-content-between darkenOnHover" v-for="item in tags.filter(tag => !tag.type || tag.type === template?.type)" :key="item.id" role="button" @click.self="addTagToContent(item)">
                    <span @click="addTagToContent(item)">
                        <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
                    </span>
                    <span class="hstack gap-1" v-if="item._id">
                        <b-button class="text-white manage-btn m-0" variant="green" @click="selectTag(item)" v-b-modal.saveTag><b-icon icon="pencil-square" /></b-button>
                        <b-button class="text-white manage-btn m-0" variant="red" @click="deleteTag(item._id)"><b-icon icon="trash" /></b-button>
                    </span>
                </div>
                <div class="text-center p-3">
                    <b-button class="text-white" variant="success" v-b-modal.saveTag>Criar Nova Variável</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="saveTag" ref="saveTag" :title="`${ selectedTag._id ? 'Editar' : 'Criar' } Variável`" centered hide-footer body-class="p-0" header-class="py-0">
            <div>
                <b-form class="vstack p-3 gap-3" @submit.prevent="saveTag()">
                    <b-form-group label="Tag:" label-class="fw-semibold text-purple">
                        <b-form-input class="border-grey" required v-model="selectedTag.name" />
                        <div class="small-text text-secondary" :class="{ 'text-danger': !selectedTag.name?.match(/^[A-Z_]*$/) }">
                            Tag deve ter todas as letras maiúsculas, não pode conter espaços, números e nem caracteres especiais ("_")
                        </div>
                    </b-form-group>
                    <b-form-checkbox class="hstack gap-2 text-purple" v-model="selectedTag.isDynamic" v-if="template?.type === 'active_communication' || (template?.type === 'campaign' && selectedTag.isDynamic)">Variável Dinâmica</b-form-checkbox>
                    <b-form-group :label="selectedTag.isDynamic ? 'Exemplo de uso:' : 'Texto:'" label-class="fw-semibold text-purple">
                        <b-form-input class="border-grey" required v-model="selectedTag.text" />
                    </b-form-group>
                    <b-form-group label="Descrição:" label-class="fw-semibold text-purple">
                        <b-form-input class="border-grey" required v-model="selectedTag.description" />
                    </b-form-group>
                    <div class="hstack gap-3">
                        <b-button type="submit" class="text-white px-4" variant="green">{{ selectedTag._id ? 'Editar' : 'Criar' }}</b-button>
                        <b-button class="border-grey px-3" variant="outline-secondary" @click="$bvModal.hide('saveTag')">Cancelar</b-button>
                    </div>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import Whatsapp from './whatsapp.vue'
import { getToken } from '../services/userService.js'
import countriesLanguagesJSON from '../utils/countriesLanguages.json'

export default {
    components: {
        Whatsapp
    },
    props: [
        'user', 'mediaURL'
    ],
    mounted: function() {
        this.getChannels()
        this.setCountriesLanguages()
        this.getTags()
        if(this.$route.query && !(Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object)) {
            const id = this.$route.query.id
            if (id) {
                this.init(id)
            }
        }
        this.onCloseModal()
    },
    data() {
        return {
            template: {
                header: "",
                content: "",
                footer: "",
                buttons: [],
                url: null,
                fileType: null,
                languageCode: "pt_BR",
                channelId: null,
            },
            loading: false,
            saving: false,
            edit: false,
            countriesLanguages: [],
            tags: [],
            pickedTags: [],
            channels: [],
            loadingTags: false,
            selectedTag: {},
            savingTag: false,
            invalidNameMessage: '',
            templateChannel: {
                apiType: ''
            },
        }
    },
    watch: {
        'selectedTag.name': function(newVal) {
            if (newVal)
                this.selectedTag.name = newVal.toUpperCase()
        }
    },
    computed: {
        messages() {
            return (this.template.header || this.template.content || this.template.footer)
                ? [{ 
                    type: 'template', 
                    body: {
                        ...this.template, 
                        components: [
                            { type: 'BODY', text: this.template.content },
                            { type: 'HEADER', format: 'TEXT', text: this.template.header },
                            { type: 'FOOTER', format: 'TEXT', text: this.template.footer },
                            { type: 'BUTTONS', buttons: this.template.buttons }
                        ] 
                    }, fromMe: true }] 
                : []
        },
        isValidName() {
            const nameRegex = /^[a-z0-9_]+$/
            if (this.template.elementName && !nameRegex.test(this.template.elementName)) {
                return false
            } else {
                return null
            }
        },
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                this.selectedTag = {}
            })
        },
        async init(id) {
            this.loading = true
            this.edit = true

            await this.getTemplate(id)

            this.loading = false
        },
        async getTemplate(id) {
            this.template = null
            
            const campaignTemplates = await api.getHsmTemplates(this.user.channelId || this.user.roleId, 'campaign')
            const attendanceTemplates = await api.getHsmTemplates(this.user.channelId || this.user.roleId, 'active_communication')

            if(campaignTemplates.statusCode === 200) {
                const template = this.getTemplateFromArray(campaignTemplates.templates, id)

                if (template)
                    this.template = { ...template, type: 'campaign' }
            }

            if(attendanceTemplates.statusCode === 200) {
                const template = this.getTemplateFromArray(attendanceTemplates.templates, id)

                if (template)
                    this.template = { ...template, type: 'active_communication' }
            }
            
            if (this.template?.channelId)
                this.templateChannel = this.channels.find(channel => channel._id === this.template.channelId)

            const regex = /\{\{[^{}]+\}\}/g; // Regular expression to match content within {{ }}
            const matches = this.template.content.match(regex); // Find all matches  
            
            if (matches) {
                for (const match of matches) {
                    const tag = match.substring(3, match.length - 2)
                    this.pickedTags.push(tag)
                }
            }

            if (!this.template) {
                this.$emit("msg", {
                    text: "Template não encontrado! Tente novamente!",
                    success: false
                })
                this.error = "Template não encontrado"
            }
        },
        getTemplateFromArray(templates, id) {
            let template = templates.find(el => el.id === id)
                if (template) {
                    if (template.containerMeta) {           
                        const containerMeta = JSON.parse(template.containerMeta)

                        if (containerMeta) {
                            containerMeta.editTemplate = undefined
                            template = { ...template, ...containerMeta, content: containerMeta.data, example: containerMeta.sampleText, url: containerMeta.mediaUrl }
                        }
                    } else if (template?.components) {
                        template.elementName = template.name
                        template.content = template.components.find(el => el.type === 'BODY')?.text
                        template.languageCode = template.language
                        template.templateType = template.components.find(el => el.type === 'HEADER')?.format || 'TEXT'
                        template.header = template.components.find(el => el.type === 'HEADER')?.text
                        template.footer = template.components.find(el => el.type === 'FOOTER')?.text
                        template.buttons = template.components.find(el => el.type === 'BUTTONS')?.buttons
                        template.url = template.components.find(el => el.type === 'HEADER')?.example?.header_handle?.[0]

                        if (template.templateType === 'BODY') {
                            template.templateType = 'TEXT'
                        }
                    }

                    if (!template.buttons)
                        template.buttons = []
                }
            return template
        },
        async saveTemplate() {
            this.saving = true

            const nameRegex = /^[a-z0-9_]+$/
            if (!nameRegex.test(this.template.elementName)) {
                return this.$emit("msg", {
                    text: "O nome deve conter apenas letras minúsculas, números e underlines!",
                    success: false
                })
            }

            if (!this.template.example)
                this.template.example = this.template.content

            if (this.template.header)
                this.template.exampleHeader = this.template.header

            if (!this.template.languageCode)
                this.template.languageCode = 'pt_BR'
            
            if (typeof this.template.buttons === 'string')
                this.template.buttons = JSON.parse(this.template.buttons)

            if (this.edit) {
                if (this.template.url)
                    this.template.mediaUrl = this.template.url

                const resp = await api.updateHSMTemplate(this.template.channelId, this.template)

                if (resp.statusCode === 200) {
                    this.$router.replace('/manageHSMModels?pending=true')
                } else {
                    if (resp.erroMensagem) {
                        this.$emit("msg", {
                            text: resp.erroMensagem,
                            success: false
                        })
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao editar template!",
                            success: false
                        })
                    }
                }
            } else {
                const resp = await api.createHSMTemplate(this.template.channelId || this.user.channelId || this.user.roleId, this.template)

                if (resp.statusCode === 201) {
                    this.$router.replace('/manageHSMModels?pending=true')
                } else {
                    if (resp.erroMensagem) {
                        this.$emit("msg", {
                            text: resp.erroMensagem,
                            success: false
                        })
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao criar template!",
                            success: false
                        })
                    }
                }
            }
            
            this.saving = false
        },
        changedCategory() {
            this.template.header = this.template.footer = this.template.content = this.template.url = this.template.fileType = ""
        },
        addButton() {
            if (!this.template.buttons)
                this.template.buttons = []

            if (typeof this.template.buttons === 'string')
                this.template.buttons = JSON.parse(this.template.buttons)

            const totalButtons = this.template.buttons.length

            if (totalButtons >= 10) {
                return this.$emit("msg", {
                    text: "Limite de botões atingido! (10)",
                    success: false
                })
            }

            this.template.buttons.push({
                type: "",
                text: `Botão ${this.template.buttons.length + 1}`,
                phone_number: "",
                url: ""
            })
        },
        buttonTypeChanged(button) {
            button.text = ""
            button.phone_number = ""
            button.url = ""

            this.buttonError = null

            const totalType = this.template.buttons.filter((el) => el.type == button.type).length
            if (button.type === 'URL' && totalType >= 3) {
                button.type = 'QUICK_REPLY'
                return this.$emit("msg", {
                    text: "Limite de botões de URL atingido! (2)",
                    success: false
                })
            } else if (button.type === 'PHONE_NUMBER' && totalType >= 2) {
                button.type = 'QUICK_REPLY'
                return this.$emit("msg", {
                    text: "Limite de botões de Telefone atingido! (1)",
                    success: false
                })
            }
        },
        removeButton(button) {
            const index = this.template.buttons.findIndex(el => el == button)
            if (index > -1)
                this.template.buttons.splice(index, 1)
            else 
                console.log('removeButton: button not found!')
        },
        async selectMenuMedia(e) {
            console.log(e)
            if (e) {
                if (e.size > 10000000) {
                    this.$emit('msg', {
                        text: "O tamanho máximo permitido para envios é de 10 MB",
                        danger: true
                    })
                }
                const formData = new FormData();
                e.filename = e.name
                formData.append('file',e)
                const typefile = e['type'].split('/')[0]
                const token = getToken()
                const headers = {'Content-Type': 'multipart/form-data', 'type': 'file', 'typefile': typefile, 'authorization': token, 'clientNumber': this.user.channelId }
                const uploadResp = await api.upload(formData,headers)
                if(uploadResp.statusCode != 200) {
                    const msg = {
                        text: "Ocorreu um erro! Tente novamente mais tarde!",
                        success: false,
                        countdown: 8
                    }
                    return this.$emit('msg',msg)
                }
                const url = uploadResp.file.uploadDir
    
                this.template.url = url
                this.template.fileType = e.type
                console.log(url)
            }
        },
        async setCountriesLanguages() {
            const aux = []

            Object.entries(countriesLanguagesJSON).map(([key, value]) => {
                aux.push({
                    label: key,
                    value
                })
            })

            this.countriesLanguages = aux
        },
        async getTags() {
            this.loadingTags = true
            
            const tags = [
                {
                    name: "DTN",
                    description: "Dia, Tarde ou Noite"
                },
                {
                    name: "NOME_CLIENTE",
                    description: "Nome do Contato"
                },
                {
                    name: "OPERADOR",
                    description: "Nome do Operador",
                    type: "active_communication"
                },
            ]
            
            const resp = await api.getMessageTags(this.user.channelId || this.user.roleId)

            this.loadingTags = false

            if (resp.statusCode === 200) {
                tags.push(...resp.tags)
            }

            this.tags = tags
        },
        async saveTag() {
            if (!this.savingTag) {
                if (!this.selectedTag.name.match(/^[A-Z_]*$/)) {
                    this.$emit("msg", {
                        text: "Tag inválida!",
                        success: false
                    })
                    return
                }
                this.savingTag = true

                if(this.selectedTag._id) { // edit
                    const resp = await api.updateMessageTag(this.selectedTag)
    
                    if (resp.statusCode === 200) {
                        this.$emit("msg", {
                            text: "Tag editada com sucesso!",
                            success: true
                        })
                        this.$bvModal.hide('saveTag')
                        this.getTags()
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao editar Tag!",
                            success: false
                        })
                    }
                } else { // create
                    const resp = await api.createMessageTag(this.user.channelId || this.user.roleId, this.selectedTag)
    
                    if (resp.statusCode === 201) {
                        this.$emit("msg", {
                            text: "Tag criada com sucesso!",
                            success: true
                        })
                        this.$bvModal.hide('saveTag')
                        this.getTags()
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro ao criar Tag!",
                            success: false
                        })
                    }
                }

                this.savingTag = false
            }
        },
        async deleteTag(tagId) {
            const resp = await api.deleteMessageTag(tagId)

            if (resp.statusCode === 200) {
                const index = this.tags.findIndex(tag => tag._id === tagId)
                this.tags.splice(index, 1)
            }
        },
        selectTag(tag) {
            this.selectedTag = tag
        },
        addTagToContent(tag) {
            const textArea = this.template.templateType === 'TEXT' ? this.$refs.textAreaText : this.$refs.textAreaMedia

            if (!textArea) return

            const sentence = textArea.vModelValue
            const len = sentence.length
            let pos = textArea.selectionStart
            if (pos === undefined) {
                pos = 0
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)

            this.template.content = `${before}{{${this.pickedTags.length + 1}}}${after}`.trim()

            this.pickedTags.push(tag.name)

            this.updateExample(true)

            this.$bvModal.hide('tags')
        },
        updateExample(addingTag = false) {
            if (!this.pickedTags.length) {
                this.template.example = this.template.content
            } else {
                let example = this.template.content

                for (let i = this.pickedTags.length - 1; i >= 0; i--) {
                    const foundTagInContent = this.template.content.includes(`{{${i + 1}}}`)

                    example = example.replace(`{{${i + 1}}}`, `{{${this.pickedTags.at(i)}}}`)

                    if (addingTag) {
                        const tag = this.tags.find(tag => tag.name === this.pickedTags.at(i))
                        if (tag && tag.isDynamic) {
                            if (!this.template.dynamicVariables) this.template.dynamicVariables = []

                            tag.number = i + 1

                            this.template.dynamicVariables.push(tag)
                        }
                    }

                    if (!addingTag && !foundTagInContent) {
                        if (this.template.dynamicVariables?.find(tag => tag.name === this.pickedTags.at(i))) {
                            this.template.dynamicVariables = this.template.dynamicVariables.filter(tag => tag.name !== this.pickedTags.at(i))
                        }

                        this.pickedTags.splice(i, 1)
                    }
                }

                this.template.example = example
            }
        },
        onContentInput() {
            this.updateExample()
        },
        onInputElementName() {
            const nameRegex = /^[a-z0-9_]+$/
            if (this.template.elementName && !nameRegex.test(this.template.elementName)) {
                this.invalidNameMessage = 'Nome inválido. O nome deve conter apenas letras minúsculas, números e underlines.'
                return false
            } else {
                this.invalidNameMessage = ''
            }
            return null
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            
            if(resp.statusCode === 200)
                return resp.channels

            return []
        },
        async getChannels() {
            const handle = async () => {
                const secondaryChannels = await this.getSecondaryChannels()
                // this.channels.push(...secondaryChannels.filter((channel) => channel.apiType === 'gupshup' || channel.apiType === 'cloud'))
                this.channels.push(...secondaryChannels.filter((channel) => channel.apiType === 'gupshup'))

                if (!secondaryChannels.length && this.template)
                    this.template.channelId = this.user.channelId || this.user.roleId
                
                if (this.template?.channelId)
                    this.templateChannel = this.channels.find(channel => channel._id === this.template.channelId)
                else
                    this.templateChannel = this.user.channel
            }

            if (this.user.channel) {
                this.channels.push(this.user.channel)
                handle()
            } else {
                await new Promise(resolve => setTimeout(resolve, 2000))
                if (this.user.channel) {
                    this.channels.push(this.user.channel)
                    handle()
                }
            }
        },
        onChannelChange() {
            if (this.template.channelId)
                this.templateChannel = this.channels.find(channel => channel._id === this.template.channelId)
        }
    }
}
</script>

<style>
    .customSelect .vs__dropdown-toggle {
        border-color: #eee;
        padding: 0.25em !important;
        border-radius: 0.375rem !important;
    }
</style>

<style scoped>
    #preview .waBg {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
    .addMedia .custom-file-input{
        display: none;
    }
    .addMedia .custom-file-label{
        display: block;
        cursor: pointer;
    }
</style>